import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Console } from 'console';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SalesService {
 // URL_API: string="http://localhost:3000";
  URL_API: string= "https://servicioscloverfile.com";
  porcentaje: string="";

  

  constructor(private httpClient: HttpClient, private cookieService: CookieService) { }

  PostLogin(req:any){
    return this.httpClient.post( this.URL_API +'/login', {mail: req.userName, password:req.password});
  }

  getBCV(){
    return this.httpClient.get("https://pydolarve.org/api/v1/dollar?page=bcv")
  }

  getcandidatos(){
    return this.httpClient.get(this.URL_API +"/candidatos")
  }

  getcompanias(){
    return this.httpClient.get(this.URL_API +"/companias")
  }

  getCompanyById(id: string): Observable<any> {
    console.log(id)
    return this.httpClient.get<any>(this.URL_API +`/company/${id}`);
  }

  getclientes(){
    return this.httpClient.get(this.URL_API +"/clientes")
  }

  getproductos(){
    return this.httpClient.get(this.URL_API +"/productos")
  }

  async getClients(): Promise<any[]> {
    const response = await fetch('https://jsonplaceholder.typicode.com/users');
    const data = await response.json();
    return data;
  }

  PostOportinidadProduct(data:any){
    console.log(data)
    return this.httpClient.post(this.URL_API +'/OportunidadProduct', { 
      OpportunitiesId:this.cookieService.get('IDoportunidad'),
      ProductsId:data.id,
      sale_price:data.sale_price,
      amount: data.amount 
    });

  }

  postOportunidad(data:any){
    if(data.etapa==="Contacto"){
      this.porcentaje ="10%"
    }else if(data.etapa==="Identificacion"){
      this.porcentaje ="20%"
    }else if(data.etapa==="Definicion"){
      this.porcentaje ="30%"
    }else if(data.etapa==="Negociacion"){
      this.porcentaje ="40%"
    } else if(data.etapa==="Cotizacion"){
      this.porcentaje ="50%"
    }else if(data.etapa==="Cerrada"){
      this.porcentaje ="100%"
    }
    console.log(data.etapa)
    
    return this.httpClient.post(this.URL_API +'/oportunidad', { 
    subject:data.oportunidad,
    candidateId:1,
    currency:data.divisa,
    stage: this.porcentaje,
    closing_date:data.fecha,
    ClientId:1,
    office:data.oficina,
    ExcecutiveId:this.cookieService.get('User') });
  }
  
  
  
  
  
  
/*   PathOportunidad(data:any){
    return this.httpClient.patch(this.URL_API+'/oportunidad/:id',{
      
    });
  } */

  GetOportunidades(){
    return this.httpClient.post(this.URL_API +'/oportunidadExcecute', { 
    ExcecutiveId:this.cookieService.get('User') });
  }

  Getproductoportunidad(id:any){

    return this.httpClient.get(this.URL_API +"/cotizaciones/oportunidad/"+ String(id))
  }

  
}
