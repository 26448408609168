<div class="btn-wrappers"
    nz-flex
    [nzJustify]="justifySegment[1]"
    [nzAlign]="alignSegment[1]">

  
        <img 
            nz-image
            style="margin-top: 70px;"
            width="290px"
            height="120px"
            nzSrc='https://www.cloverfileve.com/wp-content/uploads/2022/04/1502852123.png'
            alt=""
        />
 
</div>
       
<div >
    <div class="btn-wrappers"
    nz-flex
    [nzJustify]="justifySegment[1]"
    [nzAlign]="alignSegment[1]">
    
    
        <form  nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm()">
            
    
            <nz-form-item>
            <nz-form-control nzErrorTip="Por favor ingresa tu nombre de usuario!">
                <nz-input-group nzPrefixIcon="user">
                <input type="text" nz-input formControlName="userName" placeholder="Usuario" />
                </nz-input-group>
            </nz-form-control>
            </nz-form-item> 
            <nz-form-item>
            <nz-form-control nzErrorTip="Por favor ingresa tu contraseña!">
                <nz-input-group nzPrefixIcon="lock">
                <input type="password" nz-input formControlName="password" placeholder="Contraseña" />
                </nz-input-group>
            </nz-form-control>
            </nz-form-item>
            <div nz-row class="login-form-margin">
            <div nz-col [nzSpan]="12">
        
            </div>

            </div>
            <button nz-button class="login-form-button login-form-margin" [nzLoading]="isLoadingOne" [nzType]="'primary'">Iniciar Sesión</button>
    
        </form>
    
    </div> 

</div>



  <router-outlet name="chatbot"></router-outlet>
  <router-outlet name="footer"></router-outlet>
  <script type="module" src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.esm.js"></script>
  <script nomodule src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.js"></script>
