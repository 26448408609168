import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormGroup, FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterOutlet } from '@angular/router';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzAlign, NzFlexModule, NzJustify } from 'ng-zorro-antd/flex';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { ResetComponent } from '../reset/reset.component';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { CookieService } from 'ngx-cookie-service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { SalesService } from '../services/sales.service';

@Component({
  selector: 'app-ventas',
  standalone: true,
  imports: [
    NzSelectModule,
    NzFormModule,
    NzInputModule,
    NzButtonModule,
    ReactiveFormsModule,
    FormsModule,
    NzCheckboxModule,
    NzGridModule,
    NzFlexModule,
    NzIconModule,
    RouterOutlet,
    CommonModule,
    ResetComponent,
    NzImageModule,
    NzDividerModule,
    NzModalModule
  ],
  templateUrl: './ventas.component.html',
  styleUrl: './ventas.component.css'
})
export class VentasComponent {
  isLoadingOne = false;
  constructor(
    private fb: NonNullableFormBuilder,
    private router:Router,
    private cookieService: CookieService  ,
    private notification: NzNotificationService,
    private salesServices: SalesService
    ) {}

  public justifySegment: NzJustify[] = [
    'flex-start',
    'center',
    'flex-end',
    'space-between',
    'space-around',
    'space-evenly'
  ];
  public alignSegment: NzAlign[] = ['flex-start', 'center', 'flex-end'];  

   
  validateForm: FormGroup<{
    userName: FormControl<string>;
    password: FormControl<string>;
    remember: FormControl<boolean>;
  }> = this.fb.group({
    userName: ['', [Validators.required]],
    password: ['', [Validators.required]],
    remember: [true]
  });

  submitForm(): void {
    if (this.validateForm.valid) {
      const userName = this.validateForm.value.userName ?? '';
      this.cookieService.set('Usercap', userName);
      
      this.salesServices.PostLogin(this.validateForm.value).subscribe(
        (res: any) => {
          if (res.success === true) {
            this.router.navigate(["/ventas/menu"]);
            this.cookieService.set('User', res.user.id);
            this.createNotification('success', res.message, 'Inicio de sesión exitoso');
          } else {
            this.createNotification('error', res.message, 'Inicio de sesión');
          }
        },
        (error) => {
          // Manejo de error 401
          if (error.status === 401) {
            this.createNotification('error', 'No autorizado. Por favor verifica tus credenciales.', 'Error 401');
          } else {
            this.createNotification('error', 'Ocurrió un error. Intenta nuevamente.', 'Error');
          }
        }
      );
    } else {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  createNotification(type: string,Value:string,Titulo:string): void {
    
    this.notification.create(
      type,
      Titulo,
      Value
    );
  }

}
