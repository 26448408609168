import { Component,EventEmitter, Output  } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, NonNullableFormBuilder, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Observable, Observer } from 'rxjs';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzFlexModule,NzAlign, NzJustify } from 'ng-zorro-antd/flex';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { SocketService } from '../services/socket.service';
import { Router, RouterOutlet } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { CommonModule } from '@angular/common';
import { NzPopoverModule } from 'ng-zorro-antd/popover';


@Component({
  selector: 'app-reset',
  standalone: true,
  imports: [
    NzFormModule,
    NzInputModule,
    ReactiveFormsModule,
    NzPopoverModule,
    NzFlexModule,
    NzIconModule,
    NzImageModule,
    NzDividerModule,
    NzGridModule,
    NzButtonModule,
    NzCheckboxModule,
    CommonModule,
    ResetComponent,
    RouterOutlet
  ],
  templateUrl: './reset.component.html',
  styleUrl: './reset.component.css'
})
export class ResetComponent {
  

  public alignSegment: NzAlign[] = ['flex-start', 'center', 'flex-end']; 
  
  public justifySegment: NzJustify[] = [
    'flex-start',
    'center',
    'flex-end',
    'space-between',
    'space-around',
    'space-evenly',
    'normal'
  ];

  validateForm: FormGroup<{
    password: FormControl<string>;
    confirm: FormControl<string>;
  }>;
  Confirmar: any;
  respuesta: boolean=false;

  

  constructor(private fb: NonNullableFormBuilder, private sesion:SocketService,private router:Router,private notification: NzNotificationService) {
    this.validateForm = this.fb.group({
      password: ['', [Validators.required,Validators.minLength(8),Validators.maxLength(16)]],
      confirm: ['', [this.confirmValidator]],
    });
  }


  submitForm(): void {
    if( this.validateForm.valid){

      this.sesion.ResetPost(this.validateForm.value).subscribe((res:any)=>{
 
        if(res.status===1){
          this.createNotification('success',res.message,'Cambio de Contraseña')
          this.router.navigate(['/rrt']);
          this.respuesta= true
        }

        else if (res.status===0){
          this.router.navigate(['/reset']);
          this.createNotification('error',res.message,'Error')
        }


      });
    }else{}
    //console.log('submit error', this.validateForm.value);
    //this.createNotification('error','Error en el reseteo de contraseña','')
  }

  resetForm(e: MouseEvent): void {
    e.preventDefault();
    this.validateForm.reset();
  }

  validateConfirmPassword(): void {
    setTimeout(() => this.validateForm.controls.confirm.updateValueAndValidity());
  }

  userNameAsyncValidator: AsyncValidatorFn = (control: AbstractControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        if (control.value === 'JasonWood') {
          // you have to return `{error: true}` to mark it as an error event
          observer.next({ error: true, duplicated: true });
        } else {
          observer.next(null);
        }
        observer.complete();
      }, 1000);
    });

  confirmValidator: ValidatorFn = (control: AbstractControl) => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (control.value !== this.validateForm.controls.password.value) {
      return { confirm: true, error: true };
    }
    return {};
  };

  
  createNotification(type: string,Value:string,Titulo:string): void {
    
    this.notification.create(
      type,
      Titulo,
      Value
    );
  }
}
