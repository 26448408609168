<div nz-row nzJustify="space-around">
    <div nz-col [nzXs]="{ span: 6.3, offset: 0 }" [nzLg]="{ span: 4, offset: 0 }" >   
        <img
            nz-image
            style="margin-top: 20px;"
            width="250px"
            height="100px"
            nzSrc='https://www.cloverfileve.com/wp-content/uploads/2022/04/1502852123.png'
            alt=""
        />
        <div class="viewer" nz-col [nzXs]="{ span: 24, offset: 3 }" [nzLg]="{ span: 24, offset: 2 }" style="margin-top: 110px;">
            <p  nz-row   style="font-family: Calibri, sans-serif;font-size: 2.4em;    display: contents;">
                <b style="font-size: 22px; color: #7a828a;">Bienvenido al </b> <b style="font-size: 22px; color: #00407d;">sistema de préstamo documental de CloverFile</b> 
            </p>
        </div>
    </div>
    

    <div class="viewer" nz-row nzJustify="space-around" nzAlign="middle" >
        <div nz-col [nzXs]="{ span: 0, offset: 1 }" [nzLg]="{ span: 6, offset: 2 }" style="padding-right: 10px;">
        <img 
        class="imageMenu"
            width="220px"
            height="170px"
            nz-image 
            nzSrc='https://www.cloverfileve.com/wp-content/uploads/2022/09/3x4-06Mesa-de-trabajo-1-copia-2-5-600x422.jpg'
            alt=""
        />
        </div>
        <div nz-col [nzXs]="{ span: 0, offset: 1 }" [nzLg]="{ span: 6, offset: 2 }">
        <img class="imageMenu"
        width="220px"
        height="170px"
        nz-image
        nzSrc='https://www.cloverfileve.com/wp-content/uploads/2022/11/clvo45.jpg'
        alt=""
        />
        </div>

    </div>


    <div class="btn-wrappers"
    nz-flex
    [nzJustify]="justifySegment[1]"
    [nzAlign]="alignSegment[1]">
    <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()" class="formulario_reset">
        <nz-form-item>
        <nz-form-label [nzSpan]="24" nzRequired>Nueva Contraseña<div class="advertencia" nz-popover
            nzPopoverTitle="Requisitos"
            [nzPopoverContent]="contentTemplate"
            nzPopoverPlacement="topRight">
            <span  nz-icon nzType="exclamation" nzTheme="outline"></span>
        </div> </nz-form-label>
        <nz-form-control [nzSpan]="24" nzHasFeedback nzErrorTip="Por favor ingresa tu contraseña!">
            <input nz-input type="password" formControlName="password" (ngModelChange)="validateConfirmPassword()" />
            
        </nz-form-control>
        
        </nz-form-item>

        <nz-form-item>
        <nz-form-label [nzSpan]="12" nzRequired>Confirmación</nz-form-label>
        <nz-form-control [nzSpan]="24" nzHasFeedback [nzErrorTip]="passwordErrorTpl">
            <input nz-input type="password" formControlName="confirm" placeholder="Confirma tu contraseña" />
            <ng-template #passwordErrorTpl let-control>
            @if (control.errors?.['required']) {
                Confirma tu contraseña!
            }
            @if (control.errors?.['confirm']) {
                Contraseña inconsistente!
            }
            </ng-template>
        </nz-form-control>
        </nz-form-item>
        
        <nz-form-item>
        <div nz-col class="botones_reset" [nzSpan]="12">
            <div class="botones_reset">
            <button nz-button [nzType]="'primary'" [disabled]="!validateForm.valid">Confirmar</button>
            <button nz-button (click)="resetForm($event)">Resetear</button>
        </div>
    </div>
        </nz-form-item>
    </form>
    
        
    </div>
</div>
<ng-template #contentTemplate>
    <div>
      <p><b>Mínimo:</b> 8 Caracteres</p>
      <p><b>Máximo:</b> 16 Caracteres</p>
    </div>
  </ng-template>
  <ng-template #modalFooter>
</ng-template>


<router-outlet></router-outlet>
<router-outlet name="footer"></router-outlet>